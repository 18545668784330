<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title is-uppercase">{{ $t("about_me") }}</h1>
      <!-- <hr> -->
    </section>
    <video
      id="zpid"
      ref="zelfportretRef"
      class="about-video"
      playsinline
      loop
      controls
    />
    <hr />
    <p class="is-italic">
      {{ $t("about_me_page.p1") }} <br />
      {{ $t("about_me_page.p2") }} <br />
      {{ $t("about_me_page.p3") }} <br />
      <br />
      {{ $t("about_me_page.p4") }} <br />
      {{ $t("about_me_page.p5") }} <br />
      {{ $t("about_me_page.p6") }} <br />
      <br />
      {{ $t("about_me_page.p7") }} <br />
      <br />
      {{ $t("about_me_page.p8") }}
    </p>
    <hr />
    <section>
      <section class="mx-5">
        <!-- <b-loading
        v-model="isLoading"
        :can-cancel="true"
      /> -->
        <b-tabs v-show="!isLoading" multiline size="" expanded>
          <template v-for="(theme, index) in themes">
            <b-tab-item
              v-if="theme.displayed"
              :key="index"
              :value="theme.id"
              :label="theme.label"
            >
              <!-- <h2 class="subtitle is-size-6">
              [Beschrijving en/of jaartallen van dit thema]
            </h2> -->
              <!-- {{ total_files }} -->
              <div class="columns is-multiline">
                <div
                  v-for="(image, i) in images
                    .filter(function (str) {
                      return str.indexOf(theme.id) !== -1;
                    })
                    .slice()
                    .reverse()"
                  :key="i"
                  class="column is-3"
                >
                  <img
                    :src="require('@/assets/artotheek' + image)"
                    alt=""
                    class="image"
                  />
                </div>
              </div>
            </b-tab-item>
          </template>
        </b-tabs>
      </section>
    </section>
    <hr v-if="!isLoading" />
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Over mij",
  },
  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    images() {
      const images = require
        .context("@/assets/artotheek", false, /\.(jpg)$/)
        .keys();
      const y = images.map((s) => s.slice(1));
      return y;
    },
    themes() {
      return [
        {
          id: "z",
          label: this.$t("self_portraits"),
          description: "[Korte beschrijving van zelfportretten]",
          displayed: true,
        },
      ];
    },
  },

  methods: {
    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    },
  },
  mounted: function () {
    this.openLoading();

    this.$refs.zelfportretRef.src = require("@/assets/Zelfportret.mp4");
    this.$refs.zelfportretRef.play();
  },
};
</script>

<style lang="scss">
.about-video {
  @media only screen and (min-width: 800px) {
    width: 25%;
  }
  width: 70%;
  opacity: 1;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.5rem $black);
}
</style>>

