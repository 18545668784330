<template>
  <div>
    <!-- <video
        id="osid"
        ref="aantafelRef"
        playsinline
        loop
        controls
        class="overstijgen-video"
        muted
      /> -->
    <project-slot class="overstijgen-page">
      <template #video>
        <video
          id="osid"
          ref="aantafelRef"
          playsinline
          loop
          controls
          class="aantafel-video"
        />
      </template>

      <template #content>
        <hr />
        <div class="text-width">
          <p>
            {{ $t("overstijgen.description.p1") }}
            <router-link :to="{ name: 'Aantafel' }">
              <img
                src="@/assets/aantafel/aantafel_ei.png"
                alt=""
                style="height: 1rem"
              />
              {{ $t("aan_tafel.name") }}
            </router-link>
            {{ $t("and") }}
            <router-link :to="{ name: 'Ondergaan' }">
              <img
                src="@/assets/ondergaan/ondergaan_kristal.png"
                alt=""
                style="height: 1rem"
              />
              {{ $t("ondergaan.name") }}. </router-link
            ><br />{{ $t("overstijgen.description.p2") }}
          </p>
          <p>
            {{ $t("overstijgen.description.p3") }} <br />
            {{ $t("overstijgen.description.p4") }}
          </p>
          <p>
            {{ $t("overstijgen.description.p5") }} <br />{{
              $t("overstijgen.description.p6")
            }}
          </p>
        </div>
        <br />
        <h1 class="title">{{ $t("overstijgen.Participating_artists") }}</h1>
        <br />
        <div class="columns">
          <div
            v-for="profile in profiles"
            :key="profile.index"
            class="column mx-3"
          >
            <div class="card">
              <figure class="image profile-margin">
                <img
                  loading="lazy"
                  class="profile-image"
                  :src="
                    require('@/assets/overstijgen/profiles/' + profile.image)
                  "
                  alt="Placeholder image"
                />
              </figure>
              <div class="card-content">
                <div class="media-content">
                  <p class="title is-5">
                    {{ profile.name }}
                  </p>
                  <p class="subtitle is-6 is-italic">
                    {{ profile.work }}
                  </p>
                </div>
                <hr />
                <p class="content description">
                  {{ profile.description1 }} <br />
                  <br />
                  {{ profile.description2 }} <br />
                  <br />
                  {{ profile.description3 }} <br />
                  <br />
                  <a :href="profile.website" target="_blank">{{
                    profile.website
                  }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #description>
        <div class="text-width">
          <p class="is-italic">{{ $t("overstijgen.main.p1") }}</p>
          <p>
            {{ $t("overstijgen.main.p2") }} <br />
            {{ $t("overstijgen.main.p3") }}
          </p>
          <p>
            {{ $t("overstijgen.main.p4") }} <br />{{
              $t("overstijgen.main.p5")
            }}
          </p>
          <p>{{ $t("overstijgen.main.p6") }}</p>
        </div>
      </template>

      <template #carrousel>
        <div class="columns is-multiline is-vcentered">
          <div
            v-for="(image, i) in images"
            :key="i"
            class="column is-one-quarter"
          >
            <b-image :src="require('@/assets/overstijgen/gallery' + image)" />
          </div>
        </div>
      </template>

      <template #calendar>
        <div class="columns is-multiline is-centered">
          <div
            v-for="destination in destinations.filter(
              (destination) =>
                (destination.project == 'overstijgen' ||
                  destination.project == 'trilogica') &&
                destination.status != 'closed'
            )"
            :key="destination.id"
            class="column is-4"
          >
            <CalendarCard
              projectPage="overstijgen"
              :destination="destination"
            />
          </div>
        </div>
      </template>

      <template #review>
        <b-carousel :indicator="false">
          <b-carousel-item
            v-for="(carousel, i) in reviews.filter(
              (review) => review.project == 'OVERSTIJGEN'
            )"
            :key="i"
          >
            <section class="hero is-small">
              <div class="hero-body has-text-centered">
                <img
                  loading="lazy"
                  class="inline-phoenix"
                  src="@/assets/overstijgen/overstijgen_phoenix.png"
                  alt=""
                />
                <h1 class="subtitle aantafel-text">"{{ carousel.text }}"</h1>
                <!-- <img
                class="inline-kristal"
                src="@/assets/ondergaan_kristal.png"
                alt=""
              > -->
                <p>
                  {{ carousel.person }} <br />
                  {{ carousel.location }} - {{ carousel.date }}
                </p>
              </div>
            </section>
          </b-carousel-item>
        </b-carousel>
      </template>
      <!-- <template #currentlocation>
      Maastricht
    </template> -->

      <template #logo>
        <img
          loading="lazy"
          src="@/assets/overstijgen/overstijgen_logo.png"
          alt=""
          class="aantafel-logo"
        />
      </template>

      <template #logo-raw>
        <img
          class="overstijgen-phoenix"
          src="@/assets/overstijgen/overstijgen_phoenix.png"
          alt=""
        />
      </template>
    </project-slot>
  </div>
</template>

<script>
import projectSlot from "../components/projectSlot.vue";
import { mapGetters } from "vuex";
import CalendarCard from "../components/calendar-card.vue";

export default {
  components: {
    projectSlot,
    CalendarCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Overstijgen",
  },
  data() {
    return {
      gallery: false,

      al: {
        hasGrayscale: true,
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 4,
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 6,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["destinations", "reviews"]),
    images() {
      const images = require
        .context("@/assets/overstijgen/gallery", false, /\.(jpg)$/)
        .keys();
      const y = images.map((s) => s.slice(1));
      return y;
    },
    profiles() {
      return [
        {
          name: "Antoine van den Eertwegh",
          description1: this.$t("overstijgen.antoine.p1"),
          description2: this.$t("overstijgen.antoine.p2"),
          work: this.$t("overstijgen.antoine.work"),
          image: "AntoinevandenEertwegh.jpg",
        },
        {
          name: "Jolanda van Nuenen",
          description1: this.$t("overstijgen.jolanda.p1"),
          description2: this.$t("overstijgen.jolanda.p2"),
          work: this.$t("overstijgen.jolanda.work"),
          website: "https://jolandavannuenen.nl",
          image: "JolandavanNuenen.jpg",
        },
        {
          name: "Peter Slegers",
          description1: this.$t("overstijgen.peter.p1"),
          description2: this.$t("overstijgen.peter.p2"),
          work: this.$t("overstijgen.peter.work"),
          image: "peterslegers.jpg",
        },
      ];
    },
  },
  mounted: function () {
    this.$refs.aantafelRef.src = require("@/assets/overstijgen/OVERSTIJGEN trailer website.mp4");
    this.$refs.aantafelRef.play();
  },

  methods: {
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        return document.documentElement.classList.add("is-clipped");
      } else {
        return document.documentElement.classList.remove("is-clipped");
      }
    },

    open(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.overstijgen-page {
  // background-color: #B9BCA9;
  background-color: #f7e8cc;
  color: #8c2913;
}

.card {
  color: #8c2913;
  background: transparent;
}

.title {
  color: #8c2913;
}

.subtitle {
  color: #8c2913;
}

.description {
  font-size: 0.9rem;
}

.aantafel-video {
  @media only screen and (max-width: 800px) {
    width: 95%;
  }
  width: 100%;
  opacity: 1;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.5rem #444045);
}

.aantafel-logo {
  @media only screen and (min-width: 800px) {
    width: 50%;
  }
}

.bordered {
  border: 1px solid rgba(rgba(173, 131, 76, 1), 0.5);
  // background: radial-gradient(circle, rgba(173,131,76,1) 0%, rgb(12, 22, 21) 80%);
}

ul {
  list-style: circle;
}

.img-gallery {
  width: 100%;
  object-fit: contain;
  // object-position: center center;
}

.carrousel {
  border-radius: 6px !important;
  filter: drop-shadow(0 0 0.3rem $light);
}

.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}

.overstijgen-phoenix {
  width: 50%;
}

.current-image {
  // opacity: 0.9;'
  // padding: 0 20px;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.2rem $white);
  @media only screen and (min-width: 800px) {
    width: 40%;
  }
}

.profile-image {
  border-radius: 6px;
}

.profile-margin {
  padding: 20%;
}

.inline-phoenix {
  width: 4rem;
}

.aantafel-video {
  @media only screen and (max-width: 800px) {
    width: 95%;
  }
  width: 80%;
  opacity: 1;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.5rem #444045);
}

.overstijgen-video {
  @media only screen and (max-width: 800px) {
    width: 100%;
    height: 50vh;
  }
  width: 100%;
  height: 80vh;
  background: black;
}
</style>
