<template>
  <div class="project-wrapper has-text-centered">
    <section class="section description">
      <div class="container">
        <slot name="logo" />
        <br />
        <slot name="description" /> <br />
        <!-- <br>
        <img
          class="signature"
          src="@/assets/cropped-logo.png"
          alt=""
        > -->
      </div>
    </section>

    <section class="container">
      <slot name="video" />
    </section>

    <!-- <section
      v-if="hasTrailerSlot"
      class="section trailer"
    >
      <div class="container">
        <h2 class="project-title">
          TRAILER
        </h2>

        <slot name="trailer">
          [laatste trailer]
        </slot>
      </div>
    </section> -->

    <section
      v-if="hasCurrentLocationSlot"
      class="section location has-text-centered container"
    >
      <h2 class="project-title">
        Nu te zien in <slot name="currentlocation" />
      </h2>
      <div class="has-text-centered">
        <slot name="currentlocationlink" />
      </div>
    </section>

    <section v-if="hasContentSlot" class="section">
      <div class="container">
        <slot name="content">
          <!-- [info over inhoud project] -->
        </slot>
      </div>
    </section>

    <section v-if="hasCalendarSlot" class="section">
      <div class="container">
        <h2 class="project-title">
          {{ $t("Calendar") }}
        </h2>

        <slot name="calendar" />
        <div class="has-text-centered">
          {{ $t("check_our_agenda") }}
          <router-link :to="{ name: 'Calendar' }">
            {{ $t("here") }}
          </router-link>
        </div>
      </div>
    </section>

    <section v-if="hasCarrouselSlot" class="section carrousel">
      <div class="container">
        <h2 class="project-title">{{ $t("Gallery") }}</h2>
        <slot name="carrousel" />
      </div>
    </section>

    <section class="section extra has-text-centered">
      <div class="container">
        <slot name="extra" />
      </div>
    </section>

    <section v-if="hasReviewSlot" class="section review">
      <div class="container">
        <h2 class="project-title">{{ $t("Experiences") }}</h2>
        <slot name="review" />
      </div>
    </section>

    <section v-if="hasInstaSlot" class="section insta">
      <div class="container">
        <slot name="instafeed" />
      </div>
    </section>

    <section class="section logo">
      <div class="container has-text-centered">
        <slot name="logo-raw" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: "",
    };
  },
  computed: {
    hasContentSlot() {
      return this.$slots.content;
    },
    hasTrailerSlot() {
      return this.$slots.trailer;
    },
    hasHeaderTagSlot() {
      return this.$slots.tagheader;
    },
    hasCurrentLocationSlot() {
      return this.$slots.currentlocationlink;
    },
    hasCarrouselSlot() {
      return this.$slots.carrousel;
    },
    hasCalendarSlot() {
      return this.$slots.calendar;
    },
    hasReviewSlot() {
      return this.$slots.review;
    },
    hasInstaSlot() {
      return this.$slots.instafeed;
    },
  },
};
</script>

<style lang="scss">
.project-title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
  margin-bottom: 1.5rem;
}

.half-width {
  @media only screen and (min-width: 800px) {
    width: 40%;
  }
}

// .current-location {
//   // width: 30%;
//   margin: auto 30%;

// }

.image-style {
  opacity: 0.9;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.2rem $white);
}

.signature {
  @media only screen and (min-width: 800px) {
    width: 20%;
  }
  width: 80%;
}

// .project-wrapper {
//   background-color: $primary;
// }

.bg-wrapper {
  // background-image: url('../assets/ondergaan_background.jpeg');
  background-size: 100% 100%;
  min-height: 80vh;
  // height: 100%;
  // width: 100%;
  background-size: cover;
  background-position: center;
  // object-fit: cover;
  background-size: 100%;

  // height: 500px;
  // width: 100%
}

/* For width 400px and larger: */
// @media only screen and (max-width: 800px) {
// .bg-wrapper {
//     // background-image: url('../assets/water-bg-image.jpg');
//         background-size: 100% 100%;

//           background-size: cover;
//       background-position: center;
//       min-height: 70vh;
//   }
// }
</style>
