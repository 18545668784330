import { render, staticRenderFns } from "./Ondergaan.vue?vue&type=template&id=9e7947e6&scoped=true&"
import script from "./Ondergaan.vue?vue&type=script&lang=js&"
export * from "./Ondergaan.vue?vue&type=script&lang=js&"
import style0 from "./Ondergaan.vue?vue&type=style&index=0&id=9e7947e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e7947e6",
  null
  
)

export default component.exports