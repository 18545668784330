<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title is-uppercase">{{ $t("Gallery") }}</h1>
    </section>

    <div class="text-width">
      <p>{{ $t("shop_page.intro") }}</p>
      <p>
        {{ $t("shop_page.please_send") }}
        <a href="mailto:art@peterslegers.com?subject=AAN TAFEL Shop">mail</a>
        {{ $t("shop_page.p2") }}

        {{ $t("shop_page.p3") }}
        <a href="tel:+3-161-403-4470">
          <b-icon icon="phone" />+31 (0)6 14 03 44 70
        </a>
      </p>
    </div>
    <hr />
    <!-- Virtual tours -->
    <!--begin kunstmatrix-->
    <iframe
      allowfullscreen="true"
      frameborder="10px"
      scrolling="no"
      src="https://art.kunstmatrix.com/apps/artspaces/?external=true&language=en&uid=105049&exhibition=12735624"
      width="85%"
      height="600"
    ></iframe
    ><!--end kunstmatrix-->
    <hr />

    <!--begin kunstmatrix-->
    <iframe
      allowfullscreen="true"
      frameborder="0"
      scrolling="no"
      src="https://art.kunstmatrix.com/apps/artspaces/?external=true&language=en&uid=105049&exhibition=12770687"
      width="85%"
      height="600"
    ></iframe
    ><!--end kunstmatrix-->

    <hr />

    <!--begin kunstmatrix-->
    <iframe
      allowfullscreen="true"
      frameborder="0"
      scrolling="no"
      src="https://art.kunstmatrix.com/apps/artspaces/?external=true&uid=105049&exhibition=12791753"
      width="85%"
      height="600"
    ></iframe
    ><!--end kunstmatrix-->
    <hr />
    <!-- Balans -->
    <h2 class="subtitle has-text-weight-bold">Balans</h2>
    <div class="columns is-multiline mx-2 is-centered">
      <div
        v-for="(item, index) in shop.balans"
        :key="index"
        class="column is-4"
      >
        <div class="figure is-clickable" @click="setImage(item.image)">
          <v-lazy-image
            loading="lazy"
            class="carrousel"
            :src="require('@/assets/shop/' + item.image)"
            alt=""
          />
          <p>{{ item.description }} - {{ item.type }}</p>
          <p>{{ item.price }}</p>
        </div>
      </div>
    </div>
    <br />
    <!-- Gouden Gloed -->
    <h2 class="subtitle has-text-weight-bold">Gouden Gloed</h2>
    <div class="columns is-multiline is-centered mx-6">
      <div
        v-for="(item, index) in shop.goudenGloed"
        :key="index"
        class="column is-4"
      >
        <div class="figure is-clickable" @click="setImage(item.image)">
          <v-lazy-image
            loading="lazy"
            class="carrousel"
            :src="require('@/assets/shop/' + item.image)"
            alt=""
          />
          <p>{{ item.description }} - {{ item.type }}</p>
          <p>{{ item.price }}</p>
        </div>
      </div>
    </div>
    <br />
    <!-- Swinging beauties -->
    <!-- <h2 class="subtitle has-text-weight-bold">Swinging Beauties</h2>
    <div class="columns is-multiline mx-2">
      <div
        v-for="(item, index) in shop.swingingBeauties"
        :key="index"
        class="column is-4"
      >
        <div class="figure is-clickable" @click="setImage(item.image)">
          <v-lazy-image
            loading="lazy"
            class="carrousel"
            :src="require('@/assets/shop/' + item.image)"
            alt=""
          />
          <p>{{ item.description }} - {{ item.type }}</p>
          <p>{{ item.price }}</p>
        </div>
      </div>
    </div>
    <br /> -->
    <!-- Aluprints -->
    <!-- <h2 class="subtitle has-text-weight-bold">Aluprints AAN TAFEL</h2>
    <div class="columns is-multiline mx-2">
      <div
        v-for="(item, index) in shop.aluprints"
        :key="index"
        class="column is-4"
      >
        <div class="figure is-clickable" @click="setImage(item.image)">
          <v-lazy-image
            loading="lazy"
            class="carrousel"
            :src="require('@/assets/shop/' + item.image)"
            alt=""
          />
          <p>{{ item.description }} - {{ item.type }}</p>
          <p>{{ item.price }}</p>
        </div>
      </div>
    </div>
    <br /> -->
    <!-- Ei met foetus -->
    <!-- <h2 class="subtitle has-text-weight-bold">Ei met foetus</h2>
    <div class="columns is-multiline mx-2">
      <div
        v-for="(item, index) in shop.eiMetFoetus"
        :key="index"
        class="column is-4"
      >
        <div class="figure is-clickable" @click="setImage(item.image)">
          <v-lazy-image
            loading="lazy"
            class="carrousel"
            :src="require('@/assets/shop/' + item.image)"
            alt=""
          />
          <p>{{ item.description }} - {{ item.type }}</p>
          <p>{{ item.price }}</p>
        </div>
      </div>
    </div>
    <br /> -->
    <!-- Paradijsvogels -->
    <!-- <h2 class="subtitle has-text-weight-bold">Paradijsvogels</h2>
    <div class="columns is-multiline mx-2">
      <div
        v-for="(item, index) in shop.paradijsvogels"
        :key="index"
        class="column is-4"
      >
        <div class="figure is-clickable" @click="setImage(item.image)">
          <VLazyImage></VLazyImage>
          <v-lazy-image
            class="carrousel"
            :src="require('@/assets/shop/' + item.image)"
          />

          <p>{{ item.description }} - {{ item.type }}</p>
          <p>{{ item.price }}</p>
        </div>
      </div>
    </div> -->

    <hr />
    <br />
    <b-modal v-model="isImageModalActive">
      <figure class="image-wrapper">
        <img
          class="modal-image"
          :src="require('@/assets/shop/' + currentImage)"
        />
      </figure>
    </b-modal>
  </div>
</template>

<script>
import vLazyImage from "v-lazy-image/v2";
import VLazyImage from "v-lazy-image/v2";
import { mapGetters } from "vuex";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shop",
  },
  components: {
    VLazyImage,
    vLazyImage,
  },
  data() {
    return {
      isLoading: true,
      isImageModalActive: false,
      currentImage: "AAN TAFEL frame 01.jpg",
    };
  },
  computed: {
    ...mapGetters(["shop"]),
  },
  mounted() {
    console.log(this.shop);
  },
  methods: {
    setImage(inputImage) {
      this.isImageModalActive = true;
      this.currentImage = inputImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-gallery {
  width: 100%;
  object-fit: contain;
  // object-position: center center;
}

.carrousel {
  border-radius: 2px !important;
  filter: drop-shadow(0 0 0.1rem #444045);
  max-width: 100%;
  max-height: 500px;
  width: auto;
  height: auto;
}

.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}

.modal-image {
  max-width: 100%;
  max-height: 90vh;
  width: auto;
  height: auto;
}
</style>
