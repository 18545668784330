<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title">CONTACT</h1>
    </section>
    <div class="text-width">
      <p>{{ $t("contact_page.p1") }}</p>
      <p>{{ $t("contact_page.p2") }}</p>
    </div>
    <hr />
    <section class="">
      <div class="columns is-vcentered">
        <div class="column">
          <img
            class="contact-image"
            src="@/assets/peter_contact_1.jpg"
            alt=""
          />
        </div>
        <div class="column">
          <img
            class="contact-image"
            src="@/assets/peter_contact_2.jpg"
            alt=""
          />
        </div>
      </div>
    </section>

    <section class="section">
      <div class="column">
        <div class="has-text-centered">
          <ul class="no-bullets">
            <li>
              <a href="mailto:art@peterslegers.com">
                <b-icon icon="envelope" />art@peterslegers.com
              </a>
            </li>
            <li>
              <a href="tel:+3-161-403-4470">
                <b-icon icon="phone" />+31 (0)6 14 03 44 70
              </a>
            </li>
            <li><b-icon icon="map-pin" />Stenenwal 18b</li>
            <li><b-icon icon="location-arrow" />6221GA Maastricht</li>
          </ul>
        </div>
      </div>
    </section>
    <hr />

    <img
      src="@/assets/cropped-logo.png"
      alt=""
      style="width: 20%"
      class="my-6"
    />
    <!-- <div class="columns">
            <div class="column">
                </div>

                 <div class="column"> <img class="profile-image" src="@/assets/peter_contact_2.jpg" alt="" />
                </div>

                </div> -->
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Contact",
  },
};
</script>

<style lang="scss">
.contact-image {
  border-radius: 6px;
  filter: drop-shadow(0 0 0.5rem $primary);
  width: 95%;
  // height: 400px;
  object-fit: cover;
  object-position: center;
}
</style>
