<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="subtitle is-size-6">nieuwsbrief</h1>
      <h1 class="title is-uppercase">
        {{ thisNewsItem.articleTitle }}
      </h1>
      {{ thisNewsItem.date }}
    </section>
    <img loading="lazy" :src="thisNewsItem.image" alt="" class="news-image" />
    <section class="section news-content" v-html="thisNewsItem.html">
      {{ thisNewsItem.html }}
    </section>
    <section class="section">
      <router-link :to="{ name: 'News' }">
        <b-icon icon="chevron-left" />
        Nieuwsbrieven
      </router-link>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Nieuwsbrief",
  },
  computed: {
    ...mapGetters(["thisNewsItem"]),
  },
  mounted: function () {
    if (!this.thisNewsItem) {
      this.$router.push({ name: "News" });
    }
  },
};
</script>

<style lang="scss" scoped>
strong {
  color: whitesmoke;
}
.news-image {
  max-width: 50%;
}

.news-content {
  text-align: center !important;
}
</style>
