var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container has-text-centered"},[_c('section',{staticClass:"section"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("calendar").toUpperCase())+" ")])]),_c('div',{staticClass:"text-width"},[_c('p',[_c('router-link',{attrs:{"to":{ name: 'Trilogica' }}},[_c('img',{staticStyle:{"height":"1rem"},attrs:{"src":require("@/assets/trilogica/TRILOGICA_logo_only.png"),"alt":""}}),_vm._v(" TRILOGICA ")]),_vm._v(", "),_c('router-link',{attrs:{"to":{ name: 'Ondergaan' }}},[_c('img',{staticStyle:{"height":"1rem"},attrs:{"src":require("@/assets/ondergaan/ondergaan_kristal.png"),"alt":""}}),_vm._v(" ONDERGAAN ")]),_vm._v(", "),_c('router-link',{attrs:{"to":{ name: 'Aantafel' }}},[_c('img',{staticStyle:{"height":"1rem"},attrs:{"src":require("@/assets/aantafel/aantafel_ei.png"),"alt":""}}),_vm._v(" AAN TAFEL ")]),_vm._v(" "+_vm._s(_vm.$t("and"))+" "),_c('router-link',{attrs:{"to":{ name: 'Overstijgen' }}},[_c('img',{staticStyle:{"height":"1rem"},attrs:{"src":require("@/assets/overstijgen/overstijgen_phoenix.png"),"alt":""}}),_vm._v(" OVERSTIJGEN ")]),_vm._v(" "+_vm._s(_vm.$t("home.are_traveling_art_projects"))+" ")],1),_c('p',[_vm._v(_vm._s(_vm.$t("calendar_page.p2")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("calendar_page.p3"))+" "),_c('router-link',{attrs:{"to":{ name: 'Contact' }}},[_vm._v(" "+_vm._s(_vm.$t("contact"))+" ")]),_vm._v(" "+_vm._s(_vm.$t("calendar_page.op"))+"! ")],1),_c('p',[_vm._v(_vm._s(_vm.$t("calendar_page.p4")))])]),_c('hr',{staticClass:"horizontal-line"}),_c('section',{staticClass:"container mx-5"},[(
        _vm.destinations.filter(function (destination) { return destination.status == 'open'; })
          .length > 0
      )?[_c('h2',{staticClass:"subtitle is-size-4"},[_vm._v(" "+_vm._s(_vm.$t("calendar_page.Current_destinations"))+" ")]),_c('div',{staticClass:"columns is-multiline is-centered"},_vm._l((_vm.destinations.filter(
            function (destination) { return destination.status == 'open'; }
          )),function(destination){return _c('div',{key:destination.id,staticClass:"column is-half"},[_c('CalendarCard',{attrs:{"destination":destination}})],1)}),0),_c('hr',{staticClass:"horizontal-line"})]:_vm._e(),(
        _vm.destinations.filter(
          function (destination) { return destination.status == 'announced'; }
        ).length > 0
      )?[_c('h2',{staticClass:"subtitle is-size-4"},[_vm._v(_vm._s(_vm.$t("Soon")))]),_c('div',{staticClass:"columns is-multiline is-centered"},_vm._l((_vm.destinations.filter(
            function (destination) { return destination.status == 'announced'; }
          )),function(destination){return _c('div',{key:destination.id,staticClass:"column is-half"},[_c('CalendarCard',{attrs:{"destination":destination}})],1)}),0),_c('hr',{staticClass:"horizontal-line"})]:_vm._e(),_c('h2',{staticClass:"subtitle is-size-4"},[_vm._v(_vm._s(_vm.$t("calendar_page.Finished")))]),_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.orderedDestinations.filter(
          function (destination) { return destination.status == 'closed'; }
        )),function(destination){return _c('div',{key:destination.id,staticClass:"column is-4"},[_c('CalendarCard',{attrs:{"destination":destination}})],1)}),0),_c('hr',{staticClass:"horizontal-line"})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }