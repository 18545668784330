<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title">
        {{ $t("calendar").toUpperCase() }}
      </h1>
    </section>
    <div class="text-width">
      <p>
        <router-link :to="{ name: 'Trilogica' }">
          <img
            src="@/assets/trilogica/TRILOGICA_logo_only.png"
            alt=""
            style="height: 1rem"
          />
          TRILOGICA </router-link
        >,
        <router-link :to="{ name: 'Ondergaan' }">
          <img
            src="@/assets/ondergaan/ondergaan_kristal.png"
            alt=""
            style="height: 1rem"
          />
          ONDERGAAN </router-link
        >,
        <router-link :to="{ name: 'Aantafel' }">
          <img
            src="@/assets/aantafel/aantafel_ei.png"
            alt=""
            style="height: 1rem"
          />
          AAN TAFEL
        </router-link>
        {{ $t("and") }}
        <router-link :to="{ name: 'Overstijgen' }">
          <img
            src="@/assets/overstijgen/overstijgen_phoenix.png"
            alt=""
            style="height: 1rem"
          />
          OVERSTIJGEN
        </router-link>
        {{ $t("home.are_traveling_art_projects") }}
      </p>
      <p>{{ $t("calendar_page.p2") }}</p>
      <p>
        {{ $t("calendar_page.p3") }}
        <router-link :to="{ name: 'Contact' }">
          {{ $t("contact") }}
        </router-link>
        {{ $t("calendar_page.op") }}!
      </p>
      <p>{{ $t("calendar_page.p4") }}</p>
    </div>

    <hr class="horizontal-line" />
    <section class="container mx-5">
      <template
        v-if="
          destinations.filter((destination) => destination.status == 'open')
            .length > 0
        "
      >
        <h2 class="subtitle is-size-4">
          {{ $t("calendar_page.Current_destinations") }}
        </h2>
        <div class="columns is-multiline is-centered">
          <div
            v-for="destination in destinations.filter(
              (destination) => destination.status == 'open'
            )"
            :key="destination.id"
            class="column is-half"
          >
            <CalendarCard :destination="destination" />
          </div>
        </div>
        <hr class="horizontal-line" />
      </template>
      <template
        v-if="
          destinations.filter(
            (destination) => destination.status == 'announced'
          ).length > 0
        "
      >
        <h2 class="subtitle is-size-4">{{ $t("Soon") }}</h2>
        <div class="columns is-multiline is-centered">
          <div
            v-for="destination in destinations.filter(
              (destination) => destination.status == 'announced'
            )"
            :key="destination.id"
            class="column is-half"
          >
            <CalendarCard :destination="destination" />
          </div>
        </div>
        <hr class="horizontal-line" />
      </template>
      <h2 class="subtitle is-size-4">{{ $t("calendar_page.Finished") }}</h2>
      <div class="columns is-multiline">
        <div
          v-for="destination in orderedDestinations.filter(
            (destination) => destination.status == 'closed'
          )"
          :key="destination.id"
          class="column is-4"
        >
          <CalendarCard :destination="destination" />
        </div>
      </div>
      <hr class="horizontal-line" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import CalendarCard from "../components/calendar-card.vue";

export default {
  metaInfo: {
    title: "Kalender",
  },
  computed: {
    ...mapGetters(["destinations"]),

    orderedDestinations: function () {
      return _.orderBy(this.destinations, "key", "desc");
    },
  },
  components: {
    CalendarCard,
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
