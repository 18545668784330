<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title is-uppercase">
        {{ $t("Newsletter") }}
      </h1>
    </section>

    <div class="text-width">
      <p>{{ $t("newsletter_page.intro") }}</p>
      <p>
        {{ $t("newsletter_page.invite") }} {{ $t("newsletter_page.register") }}
        <a
          href="https://peterslegers.us14.list-manage.com/subscribe/post?u=cac5c948ba022e83dbaf5b7f3&amp;id=00913a246e"
          target="_blank"
          >{{ $t("newsletter_page.here") }}</a
        >
        {{ $t("newsletter_page.in") }}
      </p>
    </div>
    <hr />
    <section class="">
      <div class="columns is-multiline">
        <div v-for="item in newsItems" :key="item.key" class="column is-4">
          <div class="card mouseover" @click="setNewsItem(item), $router.push({ name: 'Newsview', params: { article:  item.title.replace(/\s/g, '').toLowerCase() } });
">
            <div class="card-image">
              <figure class="image is-2by1">
                <img
                  class="news-image-fit"
                  :src="item.image"
                  alt="Placeholder image"
                />
              </figure>
            </div>

            <div class="card-content">
              <p
                class="
                  subtitle
                  is-size-6
                  has-text-weight-bold has-text-link has-text-left
                "
              >
                {{ item.date }}
              </p>
              <p class="subtitle is-size-6 has-text-weight-bold has-text-left">
                {{ item.title }}
              </p>
            </div>
            <!-- <div class="card-footer has-text-centered">
                ONDERGAAN
              </div> -->
          </div>
        </div>
      </div>
    </section>
    <hr />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Nieuwsbrief",
  },
  computed: {
    ...mapGetters(["newsItems", "thisNewsItem"]),
  },
  methods: {
    ...mapActions(["setNewsItem"]),
    open(link) {
      window.open(link);
    },
  },
};
</script>

<style>
strong {
  color: whitesmoke;
}

.news-image-fit {
  object-fit: cover;
}
</style>