<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title">
        ARTOTHEEK
      </h1>
    </section>
    <h2 class="subtitle is-size-6">
      Een overzicht van mijn ambachtelijk werk gesorteerd op thema
    </h2>
    <hr>
    <section class="mx-5">
      <b-loading
        v-model="isLoading"
        :can-cancel="true"
      />
      <b-tabs
        v-show="!isLoading"
        multiline
        size=""
        expanded
      >
        <template v-for="(theme, index) in themes">
          <b-tab-item
            v-if="theme.displayed"
            :key="index"
            :value="theme.id"
            :label="theme.label"
          >
            <!-- <h2 class="subtitle is-size-6">
              [Beschrijving en/of jaartallen van dit thema]
            </h2> -->
            <!-- {{ total_files }} -->
            <div class="columns is-multiline">
              <div
                v-for="(image, i) in images
                  .filter(function (str) {
                    return str.indexOf(theme.id) !== -1;
                  })
                  .slice()
                  .reverse()"
                :key="i"
                class="column is-4"
              >
                <img
                  :src="require('@/assets/artotheek' + image)"
                  alt=""
                  class="image"
                >
              </div>
            </div>
          </b-tab-item>
        </template>
      </b-tabs>
    </section>
    <hr v-show="!isLoading">
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Artotheek",
  },
  data() {
    return {
      isLoading: true,
    }
  },

  computed: {
    images() {
      const images = require
        .context("@/assets/artotheek", false, /\.(jpg)$/)
        .keys()
      const y = images.map((s) => s.slice(1))
      return y
    },
    themes() {
      return [
        {
          id: "z",
          label: "Zelfportretten",
          description: "[Korte beschrijving van zelfportretten]",
          displayed: true,
        },
        {
          id: "po",
          label: "Portretten",
          description: "portretten",
          displayed: true,
        },
        {
          id: "a",
          label: "Abstract",
          description: "1982 - 1985: Mollerinstituut ",
          displayed: true,
        },
        {
          id: "f",
          label: "Figuratief",
          description: "1982 - 1985: Mollerinstituut ",
          displayed: true,
        },
        {
          id: "d",
          label: "Diversen",
          description: "1982 - 1985: Mollerinstituut ",
          displayed: true,
        },
        // {
        //   id: "161803",
        //   label: "Videos",
        //   content: "Videos: Lorem ipsum dolor sit amet.",
        //   displayed: true,
        // },
        // {
        //   id: "161804",
        //   label: "Games",
        //   content: "Games: Lorem ipsum dolor sit amet.",
        //   displayed: true,
        // },
        // {
        //   id: "comics",
        //   label: "Comics",
        //   content: "Comics: Lorem ipsum dolor sit amet.",
        //   displayed: true,
        // },
        // {
        //   id: "movies",
        //   label: "Movies",
        //   content: "Movies: Lorem ipsum dolor sit amet.",
        //   displayed: true,
        // },
      ]
    },
  },
  mounted: function () {
    this.openLoading()
  },
  methods: {
    openLoading() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 3000)
    },
  },
}
</script>

<style lang="scss" scoped>
.image {
  opacity: 0.9;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.2rem $white);
}
</style>>
