<template>
  <project-slot class="trilogica-page">
    <template #video>
      <video
        id="ogid"
        ref="trilogicaRef"
        playsinline
        loop
        controls
        class="ondergaan-video"
      />
    </template>
    <template #description>
      <p class="is-italic">Eureca Trilogica!</p>
      <div class="text-width">
        <p>{{ $t("trilogica.main.p1") }}</p>
        <p>{{ $t("trilogica.main.p2") }}</p>
        <p>
          {{ $t("Eureca") }}

          <router-link :to="{ name: 'Trilogica' }">
            <img
              src="@/assets/trilogica/TRILOGICA_logo_only.png"
              alt=""
              style="height: 1rem"
            />
            {{ $t("trilogica.name") }}!
          </router-link>
        </p>
        <p>{{ $t("trilogica.main.p4") }}</p>
        <!-- <p>{{ $t("trilogica.main.p5") }}</p> -->
      </div>
    </template>

    <template #calendar>
      <div class="columns is-multiline is-centered">
        <div
          v-for="destination in destinations.filter(
            (destination) =>
              destination.project == 'trilogica' &&
              destination.status != 'closed'
          )"
          :key="destination.id"
          class="column is-half"
        >
          <CalendarCard projectPage="trilogica" :destination="destination" />
        </div>
      </div>
    </template>

   <template #carrousel>
      <b-carousel :indicator-inside="false" class="carrousel-wrapper">
        <b-carousel-item v-for="(image, i) in images" :key="i" class="">
          <b-image
            class="carrousel"
            :src="require('@/assets/trilogica/gallery' + image)"
          />
        </b-carousel-item>
        <template #indicators="props">
          <!-- {{ props }} -->
          <figure class="al image" :draggable="false">
            <!-- {{ items[props.i].image }} -->
            <img
              loading="lazy"
              style="width: 1200px"
              :draggable="false"
              :src="require('@/assets/trilogica/gallery' + images[props.i])"
              :title="props.i"
            />
          </figure>
        </template>
      </b-carousel>
    </template>

    <!-- <template #content> 
      <img
        src="@/assets/trilogica/TRILOGICA_Valkenburg_affiche.jpg"
        alt=""
        style="width: 65%"
      />
      </template>      -->


    <!-- <template #instafeed>
      <h2 class="project-title">
        <a
          href="https://www.instagram.com/ondergaan/"
          class="ondergaan-text"
          target="_blank"
        >@ondergaan</a>  
      </h2>
      <div
        id="cee1e9a4_1649941950"
        class="powr-facebook-feed"
      />
    </template> -->

    <template #logo>
      <img
        loading="lazy"
        src="@/assets/trilogica/TRILOGICA logo vrijstaand.png"
        alt=""
        class="ondergaan-logo"
      />
    </template>

    <!-- <template #review>
      <b-carousel
        :indicator="false"
      >
        <b-carousel-item
          v-for="(carousel, i) in reviews.filter((review) => review.project == 'ONDERGAAN')"
          :key="i"
        >
          <section class="hero is-small ">
            <div class="hero-body has-text-centered ondergaan">
              <img
                class="inline-kristal"
                src="@/assets/ondergaan_kristal.png"
                alt=""
              >
              <h1 class="subtitle ondergaan-text">
                "{{ carousel.text }}"
              </h1>
              <p>{{ carousel.person }} <br> {{ carousel.location }} - {{ carousel.date }}</p>
            </div>
          </section>
        </b-carousel-item>
      </b-carousel>
    </template> -->

    <template #logo-raw>
      <img
        loading="lazy"
        style="width: 30%"
        src="@/assets/trilogica/TRILOGICA_logo_only.png"
        alt=""
      />
    </template>
  </project-slot>
</template>

<script>
import projectSlot from "../components/projectSlot.vue";
import { mapGetters } from "vuex";
import CalendarCard from "../components/calendar-card.vue";

export default {
  name: "Trilogica",
  components: {
    projectSlot,
    CalendarCard,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Trilogica",
  },
  data() {
    return {
      gallery: false,
      al: {
        hasGrayscale: true,
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 4,
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 6,
          },
        },
      },
      items: [
        {
          title: "Ondergaan 1",
          image: "ondergaan_galerie_009.jpg",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["destinations", "reviews"]),
        images() {
      const images = require
        .context("@/assets/trilogica/gallery", false, /\.(jpg)$/)
        .keys()
      const y = images.map((s) => s.slice(1))
      return y
    },
  },
  mounted: function () {
    this.$refs.trilogicaRef.src = require("@/assets/trilogica/TRILOGICA interview museum Valkenburg.mp4");
    this.$refs.trilogicaRef.play();
  },

  methods: {
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        return document.documentElement.classList.add("is-clipped");
      } else {
        return document.documentElement.classList.remove("is-clipped");
      }
    },
    open(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.ondergaan-page {
  background-color: #9bdfe4;
  color: #185e5e;
}

.ondergaan-video {
  @media only screen and (max-width: 800px) {
    width: 95%;
  }
  width: 80%;
  // width: 90%;
  opacity: 1;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.5rem black);
}

.ondergaan-logo {
  @media only screen and (min-width: 800px) {
    width: 50%;
  }
}

.current-image {
  opacity: 0.9;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.2rem $white);
  @media only screen and (min-width: 800px) {
    width: 30%;
  }
}

.img-gallery {
  width: 100%;
  object-fit: contain;
  // object-position: center center;
}

.carrousel-wrapper {
  border-radius: 6px !important;
  // filter: drop-shadow(0 0 0.5rem #444045);
  text-align: center;
    @media only screen and (min-width: 800px) {
      margin: 0 15%;
  }

}

.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}

.inline-kristal {
  width: 5rem;
}

.text-width {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin: 20px;
    max-width: 700px;
  }
}
</style>
