<template>
  <project-slot class="ondergaan-page">
    <template #video>
      <video
        id="ogid"
        ref="ondergaanRef"
        playsinline
        loop
        controls
        class="ondergaan-video"
      />
    </template>
    <template #description>
      <p class="is-italic">
        {{ $t("ondergaan.p1") }}
      </p>
      <br />
      <p>
        {{ $t("ondergaan.p2") }}
        <br /><br />
        {{ $t("ondergaan.p3") }}

        <br />
        {{ $t("ondergaan.p4") }}
        <br /><br />
        {{ $t("ondergaan.p5") }}
      </p>
    </template>
    <template #carrousel>
      <b-carousel :indicator-inside="false" class="carrousel">
        <b-carousel-item v-for="(image, i) in images" :key="i" class="">
          <b-image
            class="is-16by9 carrousel"
            :src="require('@/assets/ondergaan/gallery' + image)"
          />
        </b-carousel-item>
        <template #indicators="props">
          <!-- {{ props }} -->
          <figure class="al image" :draggable="false">
            <!-- {{ items[props.i].image }} -->
            <img
              loading="lazy"
              style="width: 1200px"
              :draggable="false"
              :src="require('@/assets/ondergaan/gallery' + images[props.i])"
              :title="props.i"
            />
          </figure>
        </template>
      </b-carousel>
    </template>

    <template #calendar>
      <div class="columns is-multiline is-centered">
        <div
          v-for="destination in destinations.filter(
            (destination) =>
              (destination.project == 'ondergaan' ||
                destination.project == 'trilogica') &&
              destination.status != 'closed'
          )"
          :key="destination.id"
          class="column is-4"
        >
          <CalendarCard projectPage="ondergaan" :destination="destination" />
        </div>
      </div>
    </template>

    <!-- <template #instafeed>
      <h2 class="project-title">
        <a
          href="https://www.instagram.com/ondergaan/"
          class="ondergaan-text"
          target="_blank"
        >@ondergaan</a>  
      </h2>
      <div
        id="cee1e9a4_1649941950"
        class="powr-facebook-feed"
      />
    </template> -->

    <template #logo>
      <img
        src="@/assets/ondergaan/ondergaan_logo.png"
        alt=""
        class="ondergaan-logo"
      />
    </template>

    <!-- <template #review>
      <b-carousel
        :indicator="false"
      >
        <b-carousel-item
          v-for="(carousel, i) in reviews.filter((review) => review.project == 'ONDERGAAN')"
          :key="i"
        >
          <section class="hero is-small ">
            <div class="hero-body has-text-centered ondergaan">
              <img
                class="inline-kristal"
                src="@/assets/ondergaan_kristal.png"
                alt=""
              >
              <h1 class="subtitle ondergaan-text">
                "{{ carousel.text }}"
              </h1>
              <p>{{ carousel.person }} <br> {{ carousel.location }} - {{ carousel.date }}</p>
            </div>
          </section>
        </b-carousel-item>
      </b-carousel>
    </template> -->

    <template #logo-raw>
      <img
        loading="lazy"
        class="ei"
        src="@/assets/ondergaan/ondergaan_kristal.png"
        alt=""
      />
    </template>
  </project-slot>
</template>

<script>
import projectSlot from "../components/projectSlot.vue";
import { mapGetters } from "vuex";
import CalendarCard from "../components/calendar-card.vue";

export default {
  name: "Ondergaan",
  components: {
    projectSlot,
    CalendarCard,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Ondergaan",
  },
  mounted: function () {},
  data() {
    return {
      gallery: false,
      al: {
        hasGrayscale: true,
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 4,
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 6,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["destinations", "reviews"]),
    images() {
      const images = require
        .context("@/assets/ondergaan/gallery", false, /\.(jpg)$/)
        .keys();
      const y = images.map((s) => s.slice(1));
      return y;
    },
  },
  mounted: function () {
    this.$refs.ondergaanRef.src = require("@/assets/ondergaan/ONDERGAAN promo LR.mp4");
    this.$refs.ondergaanRef.play();
  },

  methods: {
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        return document.documentElement.classList.add("is-clipped");
      } else {
        return document.documentElement.classList.remove("is-clipped");
      }
    },
    open(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.ondergaan-page {
  background-color: #9bdfe4;
  color: #185e5e;
}

.ondergaan-video {
  @media only screen and (max-width: 800px) {
    width: 95%;
  }
  width: 80%;
  // width: 90%;
  opacity: 1;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.5rem #185e5e);
}

.ondergaan-logo {
  @media only screen and (min-width: 800px) {
    width: 50%;
  }
}

.current-image {
  opacity: 0.9;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.2rem $white);
  @media only screen and (min-width: 800px) {
    width: 30%;
  }
}

.img-gallery {
  width: 100%;
  object-fit: contain;
  // object-position: center center;
}

.carrousel {
  border-radius: 6px !important;
  filter: drop-shadow(0 0 0.5rem #444045);
}

.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}

.inline-kristal {
  width: 5rem;
}
</style>
