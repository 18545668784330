<template>
  <div class="container has-text-centered">
    <section class="hero is-small">
      <div class="hero-body has-text-centered">
        <img src="@/assets/trinita_logo.png" alt="" class="home-logo" />
        <br />
        <br />
        <div class="text-width">
          <p class="is-italic">{{ $t("home.intro.italic") }}</p>
          <p>{{ $t("home.intro.middle") }}</p>
          <br />
          <p>{{ $t("home.intro.end") }}</p>
        </div>
        <hr />
        <p class="title">
          {{ $t("home.art_projects_on_tour") }}
        </p>
        <div class="columns is-centered">
          <div class="column is-4">
            <router-link
              class="card mouseover has-text-centered"
              :to="{ name: trilogica.link }"
            >
              <div class="card-image">
                <figure class="image">
                  <img
                    :src="require('@/assets' + trilogica.image)"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns is-multiline">
          <div v-for="project in projects" :key="project.id" class="column">
            <router-link
              class="card mouseover has-text-centered"
              :to="{ name: project.link }"
            >
              <div class="card-image">
                <figure class="image">
                  <img
                    :src="require('@/assets' + project.image)"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
            </router-link>
          </div>
        </div>
        <br /><br />
        <h2 class="subtitle is-size-6">
          <router-link :to="{ name: 'Trilogica' }">
            <img
              src="@/assets/trilogica/TRILOGICA_logo_only.png"
              alt=""
              style="height: 1rem"
            />
            TRILOGICA </router-link
          >,
          <router-link :to="{ name: 'Aantafel' }">
            <img
              src="@/assets/aantafel/aantafel_ei.png"
              alt=""
              style="height: 1rem"
            />
            {{ $t("aan_tafel.name") }}
          </router-link>
          ,
          <router-link :to="{ name: 'Ondergaan' }">
            <img
              src="@/assets/ondergaan/ondergaan_kristal.png"
              alt=""
              style="height: 1rem"
            />
            {{ $t("ondergaan.name") }} </router-link
          >,
          {{ $t("and") }}
          <router-link :to="{ name: 'Overstijgen' }">
            <img
              src="@/assets/overstijgen/overstijgen_phoenix.png"
              alt=""
              style="height: 1rem"
            />
            {{ $t("overstijgen.name") }}
          </router-link>
          <br />
          {{ $t("home.are_traveling_art_projects") }} <br />
          <!-- zijn reizende kunstprojecten. <br /> -->
          <br />
          {{ $t("home.check_our") }}
          <router-link :to="{ name: 'Calendar' }">
            {{ $t("calendar") }}
          </router-link>
          {{ $t("home.current_destinations") }}
        </h2>
      </div>
    </section>

    <hr />
    <!-- RECENT ITEMS -->
    <p class="title">
      {{ $t("home.recent_work") }}
    </p>
    <div v-if="recentItems" class="columns is-multiline mx-5 is-centered">
      <div
        v-for="(item, index) in recentItems"
        :key="index"
        class="column is-4"
      >
        <div
          class="figure is-clickable"
          @click="$router.push({ name: 'Gallery' })"
        >
          <v-lazy-image
            loading="lazy"
            class="recent-image"
            :src="require('@/assets/shop/' + item.image)"
            alt=""
          />
          <p>{{ item.description }} - {{ item.type }}</p>
          <p>{{ item.price }}</p>
        </div>
      </div>
    </div>
    <hr />

    <section class="">
      <div class="columns is-multiline">
        <div class="column has-text-centered">
          <h1 class="subtitle">{{ $t("Press") }}</h1>
          <div
            v-for="item in orderedPressItems.filter(
              (article) => article.onHomePage
            )"
            :key="item.key"
            class="column"
          >
            <router-link :to="{ name: 'Press' }">
              <div class="card mouseover">
                <div class="card-image">
                  <figure class="image is-2by1">
                    <img
                      class="news-image"
                      :src="require('@/assets/press/' + item.image)"
                      alt="Placeholder image"
                    />
                  </figure>
                </div>

                <div class="card-content">
                  <p
                    class="subtitle is-size-6 has-text-weight-bold has-text-link has-text-left"
                  >
                    {{ item.journal }} | {{ item.date }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="column has-text-centered">
          <h1 class="subtitle">{{ $t("Calendar") }}</h1>
          <div
            v-for="destination in destinations.filter(
              (destination) =>
                destination.status == 'open' ||
                destination.status == 'announced'
            )"
            :key="destination.key"
            class="column"
          >
            <router-link :to="{ name: 'Calendar' }">
              <CalendarCard
                :destination="destination"
                :hasFooter="false"
                :link="false"
              />
            </router-link>
          </div>
        </div>
        <div class="column has-text-centered">
          <h1 class="subtitle">{{ $t("Newsletter") }}</h1>
          <div
            v-for="item in newsItems.slice(0, 1)"
            :key="item.key"
            class="column"
          >
            <router-link :to="{ name: 'News' }">
              <div class="card mouseover">
                <div class="card-image">
                  <figure class="image is-2by1">
                    <img
                      class="news-image"
                      :src="item.image"
                      alt="Placeholder image"
                    />
                  </figure>
                </div>

                <div class="card-content">
                  <p
                    class="subtitle is-size-6 has-text-weight-bold has-text-link has-text-left"
                  >
                    {{ item.title }} | {{ item.date }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <hr />

    <div class="has-text-centered">
      <img
        loading="lazy"
        src="@/assets/trinita_logo.png"
        alt=""
        style="width: 25%"
        class="my-6"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import CalendarCard from "../components/calendar-card.vue";
import vLazyImage from "v-lazy-image/v2";

export default {
  name: "Home",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Peter Slegers",
    // all titles will be injected into this template
    titleTemplate: null,
  },
  components: {
    CalendarCard,
    vLazyImage,
  },
  data() {
    return {
      trilogica: {
        id: 1618004,
        name: "TRILOGICA",
        image: "/trilogica/TRILOGICA logo vrijstaand.png",
        description: "Trilogica on tour",
        link: "Trilogica",
      },
      projects: [
        {
          id: 1618001,
          name: "AAN TAFEL",
          image: "/aantafel/aantafel_logo.png",
          description: "Aan tafel on tour",
          link: "Aantafel",
        },
        {
          id: 1618002,
          name: "ONDERGAAN",
          image: "/ondergaan/ondergaan_logo.png",
          description: "Ondergaan on tour",
          link: "Ondergaan",
        },
        {
          id: 1618003,
          name: "Overstijgen",
          image: "/overstijgen/overstijgen_logo.png",
          description: "Overstijgen on tour",
          link: "Overstijgen",
        },
      ],
      recentItems: [],
    };
  },
  computed: {
    ...mapGetters(["newsItems", "pressItems", "destinations", "shop"]),
    orderedPressItems: function () {
      return _.orderBy(this.pressItems, "homePagePrio", "asc");
    },
    recentWorkItems() {
      const allItems = this.shop;
      return allItems;
      // console.log(this.shop);
    },
  },
  mounted() {
    this.filterRecentItems();
  },
  methods: {
    ...mapActions(["setNewsItem"]),
    open(link) {
      window.open(link);
    },
    filterRecentItems() {
      let recentItems = [];
      for (const [key, value] of Object.entries(this.recentWorkItems)) {
        value.forEach((element) => {
          if (element.isRecent) {
            recentItems.push(element);
          }
        });
      }
      this.recentItems = recentItems;
    },
  },
};
</script>

<style lang="scss">
.profile-image {
  @media only screen and (min-width: 800px) {
    width: 50%;
  }
  opacity: 1;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.5rem $primary);
}

.news-image {
  object-fit: cover;
}

//  .projects {
//    background: rgba(black, 0.2)
//  }

// .ei {
//   width: 40%
// }

.current-image {
  // opacity: 0.9;
  // border-radius: 6px;
  @media only screen and (min-width: 800px) {
    width: 40%;
  }
}

.affiche-text {
  margin: 1rem auto;
  max-width: 50rem;
  padding: 1rem;
}

.transparent-title {
  color: rgba($light, 0.4);
  font-size: 0.9rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  color: $light !important;
}

td,
th {
  /* border: 1px solid #dddddd; */
  text-align: center;
  padding: 4px;
  // /* color: white;
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.4);
}

.text-width {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin: 20px;
    max-width: 700px;
  }
}

.home-logo {
  @media only screen and (min-width: 800px) {
    max-width: 350px;
  }
}

.img-gallery {
  width: 100%;
  object-fit: contain;
  // object-position: center center;
}

.recent-image {
  border-radius: 2px !important;
  filter: drop-shadow(0 0 0.1rem #444045);
  max-width: 100%;
  max-height: 500px;
  width: auto;
  height: auto;
}
</style>
