<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title">{{ $t("Bookings").toUpperCase() }}</h1>
    </section>

    <div class="text-width">
      <p>{{ $t("bookings_page.p1") }} <br />{{ $t("bookings_page.p2") }}</p>
      <div class="has-text-centered">
        <ul class="no-bullets">
          <li>
            <a href="mailto:art@peterslegers.com">
              <b-icon icon="envelope" />art@peterslegers.com
            </a>
          </li>
          <li>
            <a href="tel:+3-161-403-4470">
              <b-icon icon="phone" />+31 (0)6 14 03 44 70
            </a>
          </li>
          <!-- <li><b-icon icon="map-pin" />Stenenwal 18b</li>
          <li><b-icon icon="location-arrow" />6221GA Maastricht</li> -->
        </ul>
      </div>
    </div>
    <hr />
    <section class="section">
      <div class="columns is-multiline">
        <div class="column is-half">
          <img :src="require('@/assets/bookings/map.jpeg')" />
        </div>
        <div class="column is-half">
          <img :src="require('@/assets/bookings/invite.jpeg')" />
        </div>
      </div>
    </section>
    <hr />
  </div>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  computed: {
    events() {
      return [
        {
          image: "aantafel/gallery/aantafel_galerie_geertruidskerk_4.jpg",
          description: this.$t("synergy_page.events.contemplation"),
        },
        {
          image: "aantafel/gallery/aantafel_galerie_zamsterdam_1.jpg",
          description: this.$t("synergy_page.events.dancing_theatre"),
        },
        {
          image: "overstijgen/gallery/Overstijgen_klasje.png",
          description: this.$t("synergy_page.events.philosophy"),
        },
        {
          image: "ondergaan/gallery/ondergaan_galerie_000.jpg",
          description: this.$t("synergy_page.events.expo"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-image {
  @media only screen and (min-width: 800px) {
    width: 85%;
  }
}
</style>
