<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title">{{ $t("crowdfunding.title") }}</h1>

      <div class="text-width">
        <!-- <p>{{ $t("support_page.intro") }}</p> -->
        <p>{{ $t("crowdfunding.p1") }}</p>
        <p>{{ $t("crowdfunding.p2") }}</p>
        <p>{{ $t("crowdfunding.p3") }}</p>
        <p>{{ $t("crowdfunding.p4") }}</p>
        <!-- <p>{{ $t("crowdfunding.p5") }}</p> -->
        <p>{{ $t("crowdfunding.p6") }}</p>
        <p>
          <button
            class="button is-rounded is-link"
            @click="makeDonation()"
          >
            <p>{{ $t("crowdfunding.p7") }}</p>
          </button>
        </p>
      </div>
    </section>
    <b-modal v-model="isImageModalActive">
      <figure class="image mx-3">
        <img
          loading="lazy"
          :src="require('@/assets/crowdfunding/' + currentImage)"
        />
      </figure>
    </b-modal>
  </div>
</template>

<script>
const ModalForm = {
  props: ["email", "password"],
  template: `
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Dank voor uw steun!</p>
                       
                    </header>
                    <section class="modal-card-body has-text-primary">
                        <div>Zojuist is een scherm geopend waar u een bijdrage kunt overmaken. <br><br> Als dank geef ik u graag bij de volgende aankoop een korting van 10% of een set kunstkaarten naar keuze. <br> U bent meer dan welkom in mijn galerie aan de maas in Maastricht, tot snel!</div>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            class="is-link"
                            label="Sluit"
                            @click="$emit('close'), $router.push({ name: 'Home'})" />
                      
                    </footer>
                </div>
            </form>
        `,
};

export default {
  data: () => {
    return {
      amount: "5",
      crowdFundingItems: [
        {
          image: "AAN TAFEL set kunstkaarten.png",
          description: "Set kunstkaarten",
        },
        {
          image: "AAN TAFEL Wijnbox.png",
          description: "AAN TAFEL wijnset + USB stick",
        },
        {
          image: "AAN TAFLE transformatie lijst.png",
          description: "Gesigneerde aquarel - AAN TAFEL",
        },
        {
          image: "ONDERGAAN kristallen lijst.png",
          description: "Gesigneerde aquarel - ONDERGAAN",
        },
        {
          image: "OVERSTIJGEN paradijsvogels lijst.png",
          description: "Gesigneerde aquarel - OVERSTIJGEN",
        },
        {
          image: "AAN TAFEL aluprint.png",
          description: "Aluprint AAN TAFEL 120x46",
        },
      ],
      isImageModalActive: false,
      currentImage: "AAN TAFEL set kunstkaarten.png",
    };
  },
  methods: {
    openModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalForm,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        canCancel: false,
      });
    },
    setImage(inputImage) {
      this.isImageModalActive = true;
      this.currentImage = inputImage;
    },
    makeDonation() {
      window.open("https://bunq.me/SteunKunstprojectenOnTour");
      this.openModal();
    },
    makeCrowdDonation() {
      window.open(
        "https://www.voordekunst.nl/projecten/14684-geef-trilogica-beeld"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-aantafel {
  background: rgba($primary, 0.5);
}

.card-header {
  background: rgba($link, 1);
  height: 100%;
}
</style>
