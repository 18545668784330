<template>
  <project-slot class="aantafel-page">
    <template #video>
      <video
        id="atid"
        ref="aantafelRef"
        playsinline
        loop
        controls
        class="aantafel-video"
      />
    </template>
    <template #description>
      <p class="is-italic">
        {{ $t("aan_tafel.main.p1") }}
      </p>
      <br />
      <p>
        {{ $t("aan_tafel.main.p2") }}
        <br /><br />
        {{ $t("aan_tafel.main.p3") }}
        <br />
        {{ $t("aan_tafel.main.p4") }} <br /><br />
        {{ $t("aan_tafel.main.p5") }}
      </p>
    </template>

    <template #content>
      <h1 class="title">
        {{ $t("aan_tafel.good_things_in_three") }}
      </h1>
      <div class="columns">
        <div
          v-for="contentCard in contentcards"
          :key="contentCard.id"
          class="column"
        >
          <figure class="image">
            <img
              loading="lazy"
              :src="require('@/assets/aantafel/' + contentCard.image)"
              alt="Placeholder image"
            />
          </figure>
          <p class="">
            {{ contentCard.description }}
          </p>
        </div>
        <!-- <div v-if="destination.status == 'open'" class="is-overlay">
              <span class="my-5">Nu te zien!</span>
              </div> -->
      </div>
    </template>
    <template #carrousel>
      <b-carousel :indicator-inside="false" class="carrousel">
        <b-carousel-item v-for="(image, i) in images" :key="i" class="">
          <b-image
            loading="lazy"
            class="is-16by9 carrousel"
            :src="require('@/assets/aantafel/gallery' + image)"
          />
        </b-carousel-item>
        <template #indicators="props">
          <figure class="al image" :draggable="false">
            <img
              loading="lazy"
              :draggable="false"
              :src="require('@/assets/aantafel/gallery' + images[props.i])"
              :title="props.i"
            />
          </figure>
        </template>
      </b-carousel>
    </template>
    <template #currentlocation> Amsterdam </template>
    <template #calendar>
      <div class="columns is-multiline is-centered">
        <div
          v-for="destination in destinations.filter(
            (destination) =>
              (destination.project == 'aantafel' ||
                destination.project == 'trilogica') &&
              destination.status != 'closed'
          )"
          :key="destination.id"
          class="column is-4"
        >
          <CalendarCard projectPage="aantafel" :destination="destination" />
        </div>
      </div>
    </template>

    <template #extra>
      <h1 class="project-title">{{ $t("aan_tafel.Arrangements.name") }}</h1>
      <div class="text-width">
        <p>{{ $t("aan_tafel.Arrangements.text") }}</p>
        <br />
        <ul class="">
          <li>{{ $t("aan_tafel.Arrangements.list.p1") }}</li>
          <li>{{ $t("aan_tafel.Arrangements.list.p2") }}</li>
          <li>{{ $t("aan_tafel.Arrangements.list.p3") }}</li>
          <li>{{ $t("aan_tafel.Arrangements.list.p4") }}</li>
          <li>{{ $t("aan_tafel.Arrangements.list.p5") }}</li>
        </ul>

        {{ $t("aan_tafel.Arrangements.possible_arrangements") }}
      </div>
      <br />
      <div class="columns">
        <div v-for="event in events" :key="event.i" class="column">
          <div class="card bordered card-aantafel">
            <header class="card-header">
              <p class="card-header-title is-bold is-size-6 has-text-centered">
                {{ event.name }}
              </p>
            </header>
            <div class="card-content is-size-7">
              <div class="columns has-text-primary">
                <div
                  v-for="(item, i) in event.content"
                  :key="item.i"
                  class="column"
                >
                  <p>
                    {{ i + 1 }} <br />
                    {{ item }}
                  </p>
                </div>
              </div>
              <!-- <ul>
      <li v-for="(item) in event.content" :key="item.i">{{item}}</li>
    </ul> -->
            </div>
          </div>
        </div>
      </div>
      <br />
      {{ $t("aan_tafel.Arrangements.cards.footer") }}!
    </template>

    <template #review>
      <b-carousel :indicator="false">
        <b-carousel-item
          v-for="(carousel, i) in reviews.filter(
            (review) => review.project == 'AAN TAFEL'
          )"
          :key="i"
        >
          <section class="hero is-small">
            <div class="hero-body has-text-centered">
              <img
                class="inline-ei"
                src="@/assets/aantafel/aantafel_ei.png"
                alt=""
              />
              <h1 class="subtitle aantafel-text">"{{ carousel.text }}"</h1>
              <!-- <img
                class="inline-kristal"
                src="@/assets/ondergaan_kristal.png"
                alt=""
              > -->
              <p>
                {{ carousel.person }} <br />
                {{ carousel.location }} - {{ carousel.date }}
              </p>
            </div>
          </section>
        </b-carousel-item>
      </b-carousel>
    </template>

    <template #logo>
      <img
        loading="lazy"
        src="@/assets/aantafel/aantafel_logo.png"
        alt=""
        class="aantafel-logo"
      />
    </template>

    <!-- <template #instafeed>
      <h2 class="project-title">
        <a
          href="https://www.instagram.com/aantafel_ontour/"
          class="aantafel-text"
          target="_blank"
        >@aantafel_ontour</a>  
      </h2>
        
      <div
        id="6ba3abdf_1649939586"
        class="powr-facebook-feed container"
      />
    </template> -->

    <template #logo-raw>
      <img class="ei" src="@/assets/aantafel/aantafel_ei.png" alt="" />
    </template>
  </project-slot>
</template>

<script>
import projectSlot from "../components/projectSlot.vue";
import { mapGetters } from "vuex";
import CalendarCard from "../components/calendar-card.vue";

export default {
  components: {
    projectSlot,
    CalendarCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "AAN TAFEL",
  },
  data() {
    return {
      gallery: false,
      al: {
        hasGrayscale: true,
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 4,
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 6,
          },
        },
      },
      currentImage: "AAN TAFEL frame 01.jpg",
      isImageModalActive: false,
      shopItems: [
        {
          image: "AAN TAFEL frame 01.jpg",
          type: "Aluprint",
          description: "Frame 1",
          price: "50x20: €144 | 80x30: €288 | 120x46: €567",
        },
        {
          image: "AAN TAFEL frame 02.jpg",
          type: "Aluprint",
          description: "Frame 2",
          price: "50x20: €144 | 80x30: €288 | 120x46: €567",
        },
        {
          image: "AAN TAFEL frame 03.jpg",
          type: "Aluprint",
          description: "Frame 3",
          price: "50x20: €144 | 80x30: €288 | 120x46: €567",
        },
        {
          image: "AAN TAFEL frame 04.jpg",
          type: "Aluprint",
          description: "Frame 4",
          price: "50x20: €144 | 80x30: €288 | 120x46: €567",
        },
        {
          image: "AAN TAFEL frame 05.jpg",
          type: "Aluprint",
          description: "Frame 5",
          price: "50x20: €144 | 80x30: €288 | 120x46: €567",
        },
        {
          image: "AAN TAFEL frame 06.jpg",
          type: "Aluprint",
          description: "Frame 6",
          price: "50x20: €144 | 80x30: €288 | 120x46: €567",
        },
        {
          image: "Ei met foetus - 01.jpg",
          type: "Aquarel",
          description: "Ei met foetus 1",
          price: "€225",
        },
        {
          image: "Ei met foetus - 02.jpg",
          type: "Aquarel",
          description: "Ei met foetus 2",
          price: "€225",
        },
        {
          image: "Ei met foetus - 03.jpg",
          type: "Aquarel",
          description: "Ei met foetus 3",
          price: "€225",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["destinations", "reviews"]),
    images() {
      const images = require
        .context("@/assets/aantafel/gallery", false, /\.(jpg)$/)
        .keys();
      const y = images.map((s) => s.slice(1));
      return y;
    },
    events() {
      return [
        {
          name: this.$t("aan_tafel.Arrangements.cards.contemplation.title"),
          content: [
            this.$t("aan_tafel.Arrangements.cards.contemplation.1"),
            this.$t("aan_tafel.Arrangements.cards.contemplation.2"),
            this.$t("aan_tafel.Arrangements.cards.contemplation.3"),
          ],
        },
        {
          name: this.$t("aan_tafel.Arrangements.cards.reading.title"),
          content: [
            this.$t("aan_tafel.Arrangements.cards.reading.1"),
            this.$t("aan_tafel.Arrangements.cards.reading.2"),
            this.$t("aan_tafel.Arrangements.cards.reading.3"),
          ],
        },
        {
          name: this.$t("aan_tafel.Arrangements.cards.education.title"),
          content: [
            this.$t("aan_tafel.Arrangements.cards.education.1"),
            this.$t("aan_tafel.Arrangements.cards.education.2"),
            this.$t("aan_tafel.Arrangements.cards.education.3"),
          ],
        },
      ];
    },
    contentcards() {
      return [
        {
          id: 161801,
          image: "aantafel_uitnodiging.png",
          description: this.$t("aan_tafel.invitation"),
        },
        {
          id: 161802,
          image: "aantafel_servering.png",
          description: this.$t("aan_tafel.serve"),
        },
        {
          id: 161803,
          image: "aantafel_beleving.png",
          description: this.$t("aan_tafel.experience"),
        },
      ];
    },
  },
  mounted: function () {
    this.$refs.aantafelRef.src = require("@/assets/aantafel/AANTAFEL_promo.mp4");
    this.$refs.aantafelRef.play();
  },
  methods: {
    getImgUrl(value) {
      value += 50;
      return `https://picsum.photos/id/10${value}/1230/500`;
    },
    setImage(inputImage) {
      this.isImageModalActive = true;
      this.currentImage = inputImage;
    },
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        return document.documentElement.classList.add("is-clipped");
      } else {
        return document.documentElement.classList.remove("is-clipped");
      }
    },
    open(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.aantafel-page {
  // background-color: #B9BCA9;
  background-color: #f0e4dc;
  color: #444045;
}

.title {
  color: #444045;
}

.aantafel-text {
  color: #444045;
}

.aantafel-video {
  @media only screen and (max-width: 800px) {
    width: 95%;
  }
  width: 80%;
  opacity: 1;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.5rem #444045);
}

.aantafel-logo {
  @media only screen and (min-width: 800px) {
    width: 50%;
  }
}

.bordered {
  border: 1px solid rgba(rgba(173, 131, 76, 1), 0.5);
  // background: radial-gradient(circle, rgba(173,131,76,1) 0%, rgb(12, 22, 21) 80%);
}

.card-header {
  background: rgba($link, 0.4);
  height: 100%;
}

.card-content {
  background: rgba(black, 0);
  // background: radial-gradient(circle, rgba(173,131,76,1) 0%, rgb(12, 22, 21) 80%);
}

// .card-footer {
//   display: block;
// }

ul {
  list-style: circle;
}

.img-gallery {
  width: 100%;
  object-fit: contain;
  // object-position: center center;
}

.carrousel {
  border-radius: 6px !important;
  filter: drop-shadow(0 0 0.5rem #444045);
}

.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}

// .card-aantafel {
//   background: rgba($primary, 0.1);
// }

.inline-ei {
  width: 4rem;
}

.current-image {
  // opacity: 0.9;'
  padding: 0 20px;
  border-radius: 6px;
  filter: drop-shadow(0 0 0.2rem $white);
  @media only screen and (min-width: 800px) {
    width: 30%;
  }
}

.card-footer {
  display: block;
  color: #444045 !important;
}
</style>
