<template>
  <div
    class="card mouseover"
    :class="{
      'aantafel-text': projectPage == 'aantafel',
      'ondergaan-text': projectPage == 'ondergaan',
      'overstijgen-text': projectPage == 'overstijgen',
      'trilogica-text': projectPage == 'trilogica',
    }"
    @click="open(destination.link)"
  >
    <div v-if="destination.project == 'aantafel'" class="card-image">
      <figure class="image">
        <img
          loading="lazy"
          src="@/assets/aantafel/aantafel_achtergrond.jpg"
          alt="Placeholder image"
        />
      </figure>
      <div class="aantafel box box--left">
        <img
          loading="lazy"
          v-if="destination.image"
          class="logo"
          :src="require('@/assets/calendar/' + destination.image)"
          alt=""
        />
        <div v-else class="text">Uw locatie?</div>
      </div>
      <div class="aantafel box box--right has-text-centered">
        <div class="text">{{ destination.location }}</div>
      </div>
    </div>

    <div v-else-if="destination.project == 'ondergaan'" class="card-image">
      <figure class="image">
        <img
          loading="lazy"
          src="@/assets/ondergaan/ondergaan_achtergrond.jpg"
          alt="Placeholder image"
        />
      </figure>
      <div class="ondergaan box box--left">
        <img
          loading="lazy"
          v-if="destination.image"
          class="logo"
          :src="require('@/assets/calendar/' + destination.image)"
          alt=""
        />
        <div v-else class="text">Uw locatie?</div>
      </div>
      <div class="ondergaan box box--right has-text-centered">
        <div class="text">{{ destination.location }}</div>
      </div>
    </div>

    <div v-else-if="destination.project == 'overstijgen'" class="card-image">
      <figure class="image">
        <img
          loading="lazy"
          src="@/assets/overstijgen/overstijgen_achtergrond.jpg"
          alt="Placeholder image"
        />
      </figure>
      <div class="overstijgen box box--left">
        <img
          v-if="destination.image"
          class="logo"
          :src="require('@/assets/calendar/' + destination.image)"
          alt=""
        />
        <div v-else class="text">Uw locatie?</div>
      </div>
      <div class="overstijgen box box--right has-text-centered">
        <div class="text">{{ destination.location }}</div>
      </div>
    </div>

    <div v-else-if="destination.project == 'trilogica'" class="card-image">
      <figure class="image">
        <img
          loading="lazy"
          src="@/assets/trilogica/TRILOGICA logo kleur.jpg"
          alt="Placeholder image"
        />
      </figure>
      <div class="trilogica box box--left">
        <img
          loading="lazy"
          v-if="destination.image"
          class="logo"
          :src="require('@/assets/calendar/' + destination.image)"
          alt=""
        />
        <div v-else class="text">Uw locatie?</div>
      </div>
      <div class="trilogica box box--right has-text-centered">
        <div class="text">{{ destination.location }}</div>
      </div>
    </div>

    <div v-else-if="destination.project == 'goudengloed'" class="card-image">
      <figure class="image">
        <img
          loading="lazy"
          src="@/assets/goudengloed/gouden-gloed-achtergrond.jpg"
          alt="Placeholder image"
        />
      </figure>
      <div class="goudengloed box box--left">
        <img
          loading="lazy"
          v-if="destination.image"
          class="logo"
          :src="require('@/assets/calendar/' + destination.image)"
          alt=""
        />
        <div v-else class="text">Uw locatie?</div>
      </div>
      <div class="goudengloed box box--right has-text-centered">
        <div class="text">{{ destination.location }}</div>
      </div>
    </div>

    <div v-else-if="destination.project == 'ertha'" class="card-image">
      <figure class="image">
        <img
          loading="lazy"
          src="@/assets/ertha/ertha-achtergrond.jpeg"
          alt="Placeholder image"
        />
      </figure>
      <div class="ertha box box--left">
        <img
          loading="lazy"
          v-if="destination.image"
          class="logo"
          :src="require('@/assets/calendar/' + destination.image)"
          alt=""
        />
        <div v-else class="text">Uw locatie?</div>
      </div>
      <div class="ertha box box--right has-text-centered">
        <div class="text">{{ destination.location }}</div>
      </div>
    </div>

    <div class="card-content has-text-left">
      <p class="is-size-5 description">
        {{ destination.name }} - {{ destination.description }}
      </p>
      <p class="is-size-6">
        {{ destination.location }}
      </p>
    </div>
    <div v-if="hasFooter" class="card-footer has-text-centered">
      <p class="card-footer-item">
        {{ destination.period }}
      </p>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import _ from "lodash";

export default {
  props: {
    destination: {
      type: Object,
      required: true,
    },
    hasFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
    projectPage: {
      type: String,
      required: false,
      default: "",
    },
    link: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    // ...mapGetters(['destinations']),

    orderedDestinations: function () {
      return _.orderBy(this.destinations, "key", "desc");
    },
  },
  methods: {
    open(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  // border: 2px solid #ffffff;
  // background-color:rgba(184,121,58, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 0.8rem;

  &--left {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40%;
    height: 40%;
  }

  &--right {
    top: 0px;
    left: 60%;
    width: 40%;
    height: 40%;
  }
}

.ondergaan {
  background-color: rgba(114, 200, 201, 0.3);
}

.aantafel {
  background-color: rgba(184, 121, 58, 0.5);
  // color: orange !important
}

.overstijgen {
  background-color: rgba(24, 23, 23, 0.4);
}

.trilogica {
  background-color: rgba(24, 23, 23, 0.4);
}

.goudengloed {
  background-color: rgba(107, 26, 26, 0.3);
}

.ertha {
  background-color: rgba(0, 0, 0, 0.3);
}

.aantafel-text {
  color: #444045;
}

// .trilogica-text {
//   color: #444045;
// }

.ondergaan-text {
  color: #185e5e;
}

.overstijgen-text {
  color: #8c2913;
}

.logo {
  position: relative;
  width: 45%;
  padding: 0;
  margin: 0;
  // height: 100%;
}

.card-content {
  height: 116px;
}

.description {
  font-weight: 700;
}
</style>
