<template>
<div class="container">
  <div class="columns is-multiline">
   <div v-for="(destination, index) in destinationsNEW" :key="index" class="column is-4">
    <div class="card mouseover"   @click="open(destination.link)"
>
      <div  v-if="destination.project == 'aantafel'" class="card-image">
        <figure class="image">
          <img
            src="@/assets/aantafel/aantafel_achtergrond.jpg"
            alt="Placeholder image"
          />
 
        </figure>
        <div class="aantafel box box--left">
          
          <img class="logo" :src="require('@/assets/calendar/' + destination.image)" alt="" />
        </div>
        <div class="aantafel box box--right has-text-centered">
          <div class="text">{{destination.location}}</div>
        </div>
      </div>

           <div  v-else-if="destination.project == 'ondergaan'" class="card-image">
        <figure class="image">
          <img
            src="@/assets/ondergaan/ondergaan_achtergrond.jpg"
            alt="Placeholder image"
          />
 
        </figure>
        <div class="ondergaan box box--left">
          
          <img class="logo" :src="require('@/assets/calendar/' + destination.image)" alt="" />
        </div>
        <div class="ondergaan box box--right has-text-centered">
          <div class="text">{{destination.location}}</div>
        </div>
      </div>

                 <div  v-else-if="destination.project == 'overstijgen'" class="card-image">
        <figure class="image">
          <img
            src="@/assets/overstijgen/overstijgen_achtergrond.jpg"
            alt="Placeholder image"
          />
 
        </figure>
        <div class="overstijgen box box--left">
          
          <img class="logo" :src="require('@/assets/calendar/' + destination.image)" alt="" />
        </div>
        <div class="overstijgen box box--right has-text-centered">
          <div class="text">{{destination.location}}</div>
        </div>
      </div>

            <div class="card-content has-text-left">
              <p class="title is-size-5">
                {{ destination.name }}  - {{ destination.description }}
              </p>
              <p class="subtitle is-size-6">
                {{ destination.location }}
              </p>
            </div>
            <div class="card-footer has-text-centered">
              <p class="card-footer-item">
                {{ destination.period }}
              </p>
            </div>
    </div>
  </div>

  </div>
  <!-- <div class="wrapper">
     <img class=""  src="@/assets/aantafel_background.jpeg" />
     <div class="box1"></div>
          <div class="box2">
             <img  src="@/assets/overstijgen/overstijgen_phoenix.png" />
          </div>

</div> --></div>
</template>
  
<script>
import { mapGetters } from "vuex"

export default {
    computed: {
    ...mapGetters(["destinationsNEW"]),
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
}



.box {
  position: absolute;
  // border: 2px solid #ffffff;
  // background-color:rgba(184,121,58, 0.8); 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 0.8rem;
  
  &--left {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40%;
    height: 40%;
  }

  &--right {
    top: 0px;
    left: 60%;
    width: 40%;
    height: 40%;
  }
  
 
}

.ondergaan {
    background-color:rgba(114,200,201, 0.5); 
}

.aantafel {
    background-color:rgba(184,121,58, 0.5); 
}

.overstijgen {
    background-color:rgba(24,23,23, 0.4); 
}



.logo {
  position: relative;
  width: 35%;
  padding: 0;
  margin: 0;
  // height: 100%;
}

.text {
  color: white
}
</style>