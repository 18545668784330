<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title">
        MEDIA
      </h1>
    </section>
    <h2 class="subtitle is-size-6">
      Hieronder een selectie van afbeeldingen en video's die ik beschikbaar stel voor
      gebruik in persberichten
    </h2>
    <hr>
    <section class="">
      <div class="columns is-multiline">
        <div
          v-for="(image, i) in images"
          :key="i"
          class="column is-4"
        >
          <img :src="require('@/assets/aantafel/gallery' + image)">
        </div>
      </div>
    </section>
    <hr>

  </div>
</template>

<script>

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Mediamap",
  },
  data: () => {
    return {
      
    }
  },
  computed: {
    images() {
      const images = require
        .context("@/assets/aantafel/gallery", false, /\.(jpg)$/)
        .keys()
      const y = images.map((s) => s.slice(1))
      return y
    },
  },
  methods: {
      }
}
</script>

<style>
</style>