<template>
  <div class="container has-text-centered">
    <section class="section">
      <h1 class="title is-uppercase">{{ $t("Press") }}</h1>
    </section>
    <h2 class="subtitle is-size-6">
      {{ $t("press_page.intro") }}
    </h2>
    <hr />
    <section class="">
      <div class="columns is-multiline">
        <div v-for="item in pressItems" :key="item.key" class="column is-4">
          <div class="card mouseover" @click="open(item.link)">
            <div class="card-image">
              <figure class="image is-2by1">
                <img
                  loading="lazy"
                  class="news-image"
                  :src="require('@/assets/press/' + item.image)"
                  alt="Placeholder image"
                />
              </figure>
            </div>

            <div class="card-content">
              <p
                class="
                  subtitle
                  is-size-6
                  has-text-weight-bold has-text-link has-text-left
                "
              >
                {{ item.journal }} | {{ item.date }}
              </p>
              <p class="subtitle is-size-6 has-text-weight-bold has-text-left">
                "{{ item.title }}"
              </p>
            </div>
            <!-- <div class="card-footer has-text-centered">
                ONDERGAAN
              </div> -->
          </div>
        </div>
      </div>
    </section>
    <hr />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Pers",
  },
  computed: {
    ...mapGetters(["pressItems"]),
  },
  methods: {
    open(link) {
      window.open(link);
    },
  },
};
</script>

<style>
</style>